.icon {
  align-self: stretch;
  transition: all .5s;
  color: #aaa;
  background-color: transparent;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px
}

@media (hover:hover) {
  .icon:hover {
    color: white;
  }
}
