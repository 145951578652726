.button {
  color: #888;
  background: black;
  padding: 0 12px;
  margin: 0;
  transition: all .5s;
  border: 0;
  outline: 0;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.selected-button {
  composes: button;
  color: #ccc;
  background: rgba(255, 255, 255, 0.2);
}

.button:disabled {
  opacity: .4;
}

.icon {
  font-size: 14px;
  align-self: center;
}

.label {
  margin-left: 8px;
  font: 14px Helvetica Neue, Helvetica, Arial, Sans-Serif;
  align-self: center;
}

@media (hover:hover)
{
  .button:enabled:hover {
    color: #fff;
    background: rgba(255, 255, 255, 0.25);
  }
}

@media only screen and (max-width: 450px) {
  .button {
    flex-direction: column;
    justify-content: center;
    padding: 0 16px;
  }

  .label {
    font-size: 12px;
    margin-left: 0;
    margin-top: 4px;
    min-width: 32px;
  }
}
