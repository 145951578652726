.box {
  display: flex;
  flex-direction: column;
}

.fault {
  display: flex;
  flex-direction: row;
  margin: 4px;
  padding: 8px;
  border: 1px solid #fa88;
  box-shadow: 3px 3px 4px #b654;
  background: #fa84;
}

.icon {
  margin-right: 12px;
  font-size: 22px;
  color: #a77;
}

.info {
  display: flex;
  flex-direction: column;
}

.message {
  font-size: 14px;
  font-weight: 500;
}

.source {
  font-size: 13px;
  color: #666;
}

.ts {
  font-size: 12px;
  color: #777;
}
