.fault {
  margin: 6px 0;
  font-size: 14px;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
}

.icon {
  font-size: 24px;
  margin-right: 16px;
}

.info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.message {
  font-size: 18px;
}

.extra {
  font-size: 13px;
}

.extra a {
  margin-left: 12px;
  color: cornflowerblue;
  cursor: pointer;
}


.detail-window {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.details {
  display: grid;
  grid-template-columns: max-content auto;
  grid-gap: 10px 32px;
  gap: 10px 32px;
  color: #333;
}

.label {
  color: #555;
}

.value {
}

.pre-value {
  white-space: pre;
  font-family: Menlo, monospace;
  font-size: 12px;
  max-width: 425px;
  overflow-x: auto;
}

@media (hover:hover) {
  .extra a:hover {
    color: navy;
  }
}
