.root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  flex-grow: 1;
}

.left {
  display: flex;
  flex-direction: column;
}

.temperature {
  font-size: 24px;
  color: #666;
  line-height: 1.1;
  flex-grow: 1;
}

.state, .humidity, .dewpoint {
  margin-left: 1px;
  color: #666;
  font-size: 12px;
  line-height: 1.1;
}

.right-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  margin-left: 4px;
}

.mode {
  width: 80px;
}

.setpoint {
  width: 48px;
}

.heat-setpoint {
  composes: setpoint;
  background: #fee;
}

.cool-setpoint {
  composes: setpoint;
  background: #eef;
}
