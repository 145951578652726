.root {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dimmer {
  border: 2px solid #ccc;
  border-radius: 4px;
  flex-grow: 1;
  height: 28px;
  cursor: pointer;
  display: grid;
}

.bar {
  height: 100%;
  background: rgba(68, 68, 119, 0.2);
  grid-row: 1;
  grid-column: 1;
}

.level {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: black;
  grid-row: 1;
  grid-column: 1;
}

.power {
  font-size: 24px;
  color: #a66;
  opacity: 0.5;
  cursor: pointer;
  margin-left: 12px;
}

.power-on {
  composes: power;
  color: #6a6;
  opacity: 1.0;
}
