.frame {
  font: 16px "San Francisco", "Helvetica Neue", Helvetica, Arial, Sans-Serif;
  background: #222;
  border: 1px solid #111;
  border-radius: 16px;
  box-shadow: 8px 8px 16px #111;
  display: flex;
  flex-direction: column;
  color: #eee;
  padding: 16px;
  user-select: none;
  -webkit-user-select: none;
}

.title {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  font-size: 24px;
}

.tag-icon {
  font-size: 24px;
  margin-right: 16px;
  color: #ddd;
}

.title-text {
  flex: 1;
}

.close-icon {
  font-size: 22px;
  align-self: flex-start;
  color: #aaa;
}

.options {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
}

.option {
  display: flex;
  letter-spacing: .1em;
  width: 200px;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #888;
  margin-right: 16px;
  cursor: pointer;
}

.option:last-of-type {
  margin-right: 0;
}

.select-option {
  composes: option;
  background: linear-gradient(to bottom, olive, gold, darkgoldenrod);
}

.disabled-option {
  composes: option;
  opacity: .4;
  filter: blur(3px);
  cursor: default;
}

.option-icon {
  font-size: 80px;
  min-width: 100px;
  max-width: 100px;
}

.option-label {
  margin-top: 16px;
  font-size: 14px;
}

.actions {
  display: flex;
  flex-direction: row;
}

.action {
  flex-grow: 1;
  flex-basis: 0;
  margin-top: 16px;
  font-size: 18px;
  text-align: center;
  letter-spacing: .1em;
  margin-right: 8px;
  padding: 16px;
  border-radius: 20px;
  border: 1px solid #556;
  cursor: pointer;
  background: linear-gradient(to bottom, midnightblue, steelblue, midnightblue);
}

.disarm {
  composes: action;
  background: linear-gradient(to bottom, maroon, red, maroon);
}

.action:last-of-type {
  margin-right: 0;
}

@media only screen and (max-width: 767px) {
  .frame {
    padding: 8px;
    border-radius: 10px;
  }

  .title {
    font-size: 20px;
  }

  .tag-icon {
    font-size: 20px;
  }

  .option {
    width: 140px;
    margin-right: 8px;
  }

  .option-label {
    font-size: 12px;
  }

  .action {
    font-size: 16px;
  }
}
