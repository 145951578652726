.header {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: stretch;
}

.left {
  composes: header;
  justify-content: flex-start;
}

.center {
  composes: header;
  justify-content: center;
}

.right {
  composes: header;
  justify-content: flex-end;
}
