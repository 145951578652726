:global(#header) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 50px;
  position: fixed;
  top: 0;
  z-index: 30000;
  background-color: black;
  color: #eee;
  width: 100%;
}

:global(#page-selector) {
  display: flex;
  flex-direction: row;
}

:global(#page-header) {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

:global(#common-header) {
  align-self: stretch;
  display: flex;
  flex-direction: row;
}

:global(#main) {
}
