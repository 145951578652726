.root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  flex-grow: 1;
}

.temp-column {
  width: 72px;
  display: flex;
  flex-direction: column;
}

.wind-column {
  width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.misc-column {
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.temperature {
  font-size: 24px;
  color: #666;
  line-height: 1.1;
  flex-grow: 1;
}

.dewpoint, .apparent, .wind-gust {
  margin-left: 1px;
  color: #666;
  font-size: 12px;
  line-height: 1.1;
}

.section {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-head {
  font-size: 10px;
  color: #555;
  background: #e8e8e8;
  border-radius: 3px;
  padding-top: 1px;
  padding-bottom: 1px;
  margin-bottom: 2px;
  text-transform: uppercase;
  text-align: center;
  align-self: stretch;
  font-weight: bold;
}

.baro, .rain-rate {
  color: #666;
  font-size: 12px;
  line-height: 1.1;
}

.barometer-trend {
  padding-left: 4px;
  font-size: 10px;
}

.compass {
  display: block;
  width: 48px;
  height: 48px;
  border: 2px solid #888;
  border-radius: 100%;
  position: relative;
  color: #444;
  margin-bottom: 6px;
}

.text-overlay {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  text-align: center;
  font-size: 18px;
  margin-top: -11px;
  left: 0;
  top: 50%;
  color: #444;
}

.arrow {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  transition: transform 0.2s;
}

.arrow:after {
  content: '';
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 14px solid red;
  position: absolute;
  top: -6px;
  left: 50%;
  margin-left: -5px;
}
