.root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  flex-grow: 1;
}

.left {
  display: flex;
  flex-direction: column;
}

.watt {
  font-size: 24px;
  color: #666;
  line-height: 1.1;
  flex-grow: 1;
}

.voltage, .current {
  margin-left: 1px;
  color: #666;
  font-size: 12px;
  line-height: 1.1;
}
