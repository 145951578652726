.root {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.unavail {
  composes: root;
  filter: blur(5px);
}

.main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.status, .trip {
  margin: 4px 0;
  font-size: 13px;
  color: #444;
}

.line-icon {
  margin-right: 8px;
  color: slategray;
}

.edit-icon {
  font-size: 32px;
  color: midnightblue;
}

.edit-armed {
  font-size: 32px;
  color: firebrick;
}
