.field-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.field {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--field-vertical-spacing, 20px);
}

.grow-field {
  composes: field;
  flex: 1 1;
}

.field-group .field {
  margin-right: var(--field-group-spacing, 32px);
}

.field-group .field:last-child {
  margin-right: 0;
}

.label {
  font: var(--field-label-font, 12px Sans-Serif);
  margin-bottom: 4px;
  color: gray;
  text-transform: uppercase;
}

.line {
  margin-top: 4px;
  border: solid black;
  border-width: 0 0 1px 0;
}

.error-line {
  composes: line;
  border-color: red;
}

.focus-line {
  composes: line;
  border-color: #0ca;
  box-shadow: 0px 0px 4px 2px #0ec;
}

.error-message {
  margin-top: 3px;
  font: var(--field-error-font, 12px Sans-Serif);
  color: red;
}

@media screen and (max-width: 767px) {
  .field-group {
    flex-direction: column;
    justify-content: flex-start;
  }

  .field {
    flex: 1 1;
  }

  .field-group .field {
    margin-right: 0;
  }
}
