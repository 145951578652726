.window {
  background: linear-gradient(to bottom right, #d0d0e0, white);
  border-radius: 12px;
  font: 16px "San Francisco", "Helvetica Neue", Helvetica, Arial, Sans-Serif;
  box-shadow: 8px 8px 16px #444;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.header {
  display: grid;
  background: linear-gradient(to right, #99a, #ccd, #99a);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.title {
  grid-row: 1;
  grid-column: 1;
  font-size: 18px;
  color: #444;
  text-align: center;
  padding: 7px 0;
}

.close {
  grid-row: 1;
  grid-column: 1;
  justify-self: end;
  align-self: center;
  margin-right: 16px;
  font-size: 24px;
  color: #eee;
  cursor: pointer;
}

.body {
  margin: 16px;
}
