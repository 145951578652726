.root {
  padding: 4px;
}

.chrome {
  padding: 4px;
  border: 1px solid #ccc;
  box-shadow: 3px 3px 4px #7776;
  display: flex;
  flex-direction: column;
  cursor: default;
}

.normal {
  composes: chrome;
  height: 116px;
}

.graph {
  composes: chrome;
  height: 240px;
}

.top {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 22px;
}

.widget {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  flex-grow: 1;
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 5px;
  margin-bottom: 3px;
}

.type-icon {
  font-size: 22px;
  margin-right: 8px;
  color: #779;
  min-width: 28px;
}

.title {
  font: Futura, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  flex-grow: 1;
}

.fav-icon {
  font-size: 18px;
  margin: 0 0 0 5px;
  color: #aa8;
  align-self: flex-start;
  border: 0;
  background: transparent;
  padding: 0;
  cursor: pointer;
  outline: none;
}

.fav-active {
  composes: fav-icon;
  color: #cc4;
}

.fav-changed {
  composes: fav-icon;
  animation: fav-magnify 0.2s linear 0s reverse;
}

.fav-active-changed {
  composes: fav-icon;
  color: #cc4;
  animation: fav-magnify 0.2s linear 0s reverse;
}


@keyframes fav-magnify {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(3.0);
  }
}
