.form {
  display: flex;
  flex-direction: column;
}

.form-text {
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid #bbb;
}

.form-text p {
  margin: 0 4px;
}
