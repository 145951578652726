.alert-main {
  display: flex;
  flex-direction: row;
  margin: 0px 24px 0px 8px;
}

.alert-icon {
  font-size: 24px;
  margin-right: 12px;
  align-self: top;
}

.alert-content {
  align-self: top;
  display: flex;
  flex-direction: column;
}

.alert-clickable-content {
  composes: alert-content;
  cursor: pointer;
}

.alert-caption {
  font-size: 120%;
  margin-bottom: 8px;
}

.alert-body {
}

.close-icon {
  font-size: 16px;
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
}

.simple-modal {
  background: linear-gradient(to bottom right, #d0d0e0, white);
  border-radius: 12px;
  padding: 32px;
  font: 16px San Francisco, Helvetica Neue, Helvetica, Arial, Sans-Serif;
  box-shadow: 8px 8px 16px #444;
  display: flex;
  flex-direction: column;
  justify-content: start;
  max-width: 500px;
}

.simple-modal p {
  margin: 8px 0;
}
