.button-base {
  padding: 6px 16px;
  border-radius: 8px;
  font: var(--button-font, 14px Sans-Serif);
  cursor: pointer;
  transition: background 500ms, opacity 250ms;
  outline: none;
}

.button-base:disabled {
  opacity: var(--disabled-opacity, .4);
  cursor: default;
}

.secondary {
  composes: button-base;
  border: 1px solid #ccc;
  background-color: white;
  color: #333;
}

.secondary:hover:enabled {
  background-color: #eee;
}

.primary {
  composes: button-base;
  border: 1px solid #2196f3;
  background-color: #2196f3;
  color: white;
}

.primary:hover:enabled {
  background-color: #47a7f5;
}

.success {
  composes: button-base;
  border: 1px solid darksalmon;
  background-color: #0b0;
  color: #eee;
}

.success:hover:enabled {
  background-color: #0d0;
}

.warning {
  composes: button-base;
  border: 1px solid darkkhaki;
  background-color: khaki;
  color: #333;
}

.warning:hover:enabled {
  background-color: darkkhaki;
}

.danger {
  composes: button-base;
  border: 1px solid darksalmon;
  background-color: salmon;
  color: #eee;
}

.danger:hover:enabled {
  background-color: lightsalmon;
}

.group {
  display: flex;
  flex-direction: row;
}

.group-left {
  composes: group;
  justify-content: flex-start;
}

.group-center {
  composes: group;
  justify-content: center;
}

.group-right {
  composes: group;
  justify-content: flex-end;
}

.group > .button-base {
  margin-left: 16px;
}

.group > .button-base:first-child {
  margin-left: 0;
}

.column {
  display: flex;
  flex-direction: column;
}

.column-top {
  composes: column;
  justify-content: flex-start;
}

.column-center {
  composes: column;
  justify-content: center;
}

.column-bottom {
  composes: column;
  justify-content: flex-end;
}

.column > .button-base {
  margin-top: 10px;
}

.column > .button-base:first-child {
  margin-top: 0;
}

.pressed {
  animation: button-press 0.25s linear 0s reverse;
}

@keyframes button-press {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
