.sections {
  margin: 16px 16px;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 24px;
}

.section {
  border: 1px solid #ccc;
  box-shadow: 5px 5px 5px #ccc;
}

.top {
  display: flex;
  flex-direction: row;
  margin: 4px 16px;
  font-size: 24px;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 12px;
  border-bottom: 1px solid #aaa;
}

.icon-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 32px;
}

.icon {
  color: black;
  align-self: center;
}

.title {
  margin-left: 16px;
  flex-grow: 1;
}

.actions {
}

.content {
  margin: 0 16px 16px 16px;
}

.empty {
  font-size: 16px;
  padding: 8px 16px;
}

.break {
  border-bottom: 1px solid #aaa;
}
