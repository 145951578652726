.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.left {
  margin-left: 2px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.status-line {
  display: flex;
  flex-direction: row;
  margin: 2px 0;
}

.status-icon {
  margin-right: 8px;
  font-size: 16px;
  min-width: 20px;
}

.ok {
  composes: status-icon;
  color: #5cb85c;
}

.info {
  composes: status-icon;
  color: #31708f;
}

.warning {
  composes: status-icon;
  color: #f0ad4e;
}

.danger {
  composes: status-icon;
  color: #d9534f;
}

.status-label {
  font-size: 13px;
}

.right {
  display: flex;
  flex-direction: row;
  align-self: center;
}

.bypass {
  width: 110px;
}
