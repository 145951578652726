.main {
  display: flex;
  flex-direction: row;
  font: var(--alert-font, "14px sans-serif");
  border-radius: var(--alert-border-radius, 8px);
  padding: var(--inline-alert-padding, 10px);
  margin-bottom: 16px;
}

.success {
  composes: main;
  background: var(--success-alert-bg, silver);
  color: var(--success-alert-text-color, black);
  box-shadow: var(--success-inline-box-shadow, unset);
}

.info {
  composes: main;
  background: var(--info-alert-bg, silver);
  color: var(--info-alert-text-color, black);
  box-shadow: var(--info-inline-box-shadow, unset);
}

.error {
  composes: main;
  background: var(--error-alert-bg, silver);
  color: var(--error-alert-text-color, black);
  box-shadow: var(--error-inline-box-shadow, unset);
}

.warning {
  composes: main;
  background: var(--warning-alert-bg, silver);
  color: var(--warning-alert-text-color, black);
  box-shadow: var(--warning-inline-box-shadow, unset);
}

.icon {
  font-size: 24px;
  margin-right: 12px;
  align-self: top;
}

.content {
  align-self: top;
  display: flex;
  flex-direction: column;
}

.caption {
  font-size: 120%;
  margin-bottom: 8px;
}

.body {
}
