.root {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 8px;
  margin: 4px 6px 4px 4px;
}

.base-scene {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  transition: background 500ms;
  -webkit-tap-highlight-color: transparent;
}

.scene {
  composes: base-scene;
  background: #f8f8f8;
  border: 1px solid #ccc;
  box-shadow: 2px 2px 3px #999;
}

.active-scene {
  composes: base-scene;
  background: #e8e8b0;
  border: 1px solid #cc8;
  box-shadow: 2px 2px 3px #994;
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.busy-inner {
  composes: inner;
  cursor: wait;
  opacity: .5;
}

.icon {
  font-size: 40px;
}

.name {
  min-width: 0;
  max-width: 100%;
  margin-top: 16px;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (min-width: 1024px) and (max-width: 1450px) {
  .root {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .root {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media only screen and (max-width: 767px) {
  .root {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
