.default {
  width: 320px;
  height: 240px;
}

.canvas {
  width: 100%;
  height: 100%;
  position: absolute;
}

.main {
  composes: canvas;
}

.overlay {
  composes: canvas;
}

.clickable-overlay {
  composes: canvas;
  cursor: pointer;
}
