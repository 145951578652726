.alternator {
  flex-grow: 1;
  align-self: stretch;
  display: grid;
}

.view {
  grid-column: 1;
  grid-row: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.chooser {
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.option {
  margin: 0 2px 0 2px;
  padding: 5px 12px; 
  font-size: 12px;
  border: 2px solid #ccc;
  border-radius: 4px;
  text-align: center;
  background: #f4f4f4;
  cursor: pointer;
}

.option-select {
  composes: option;
  background: #dcdcdc;
}

.option-busy {
  composes: option;
  opacity: 0.4;
  cursor: wait;
}

.option-primary {
  composes: option;
}

.option-danger {
  composes: option;
  background: #ffdcdc;
}

.slider {
  flex-grow: 1;
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.slider-input {
  margin-left: 16px;
  font-size: 14px;
  flex-grow: 1;
}

.slider-value {
  margin-left: 8px;
  margin-right: 16px;
  font-size: 19px;
}

.slider-commit {
  margin-left: 13px;
}
