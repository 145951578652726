.root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  flex-grow: 1;
}

.left {
  display: flex;
  flex-direction: column;
}

.temperature {
  font-size: 24px;
  color: #666;
  line-height: 1.1;
  flex-grow: 1;
}

.humidity, .dewpoint {
  margin-left: 1px;
  color: #666;
  font-size: 12px;
  line-height: 1.1;
}

.right {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
}

.battery {
  color: #666;
  font-size: 12px;
  line-height: 1.0;
}
