.button {
  margin: 3px 0px;
  padding: 4px 0; 
  font-size: 12px;
  border: 2px solid #ccc;
  border-radius: 4px;
  text-align: center;
  background: #f4f4f4;
  cursor: pointer;
}

.busy-button {
  composes: button;
  cursor: wait;
}

.button:disabled {
  opacity: 0.4;
  cursor: default;
}
