.overlay {
  z-index: 1;
  position: absolute;
  display: flex;
  flex-direction: column;
  margin-left: -8px;
  padding: 0;
  background-color: white; 
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 4px 4px 8px 4px #ccc;
  overflow: hidden;
  outline: none;
}
