.root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  flex-grow: 1;
}

.left {
  display: flex;
  flex-direction: column;
}

.position {
  margin-top: 8px;
  font-size: 18px;
  color: #666;
  line-height: 1.1;
  flex-grow: 1;
}

.battery {
  margin-left: 1px;
  color: #666;
  font-size: 12px;
  line-height: 1.1;
}

.right {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.action {
  width: 60px;
  margin: 0 2px;
}
