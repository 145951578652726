.search {
  font-family: "Helvetica Neue", Helvetica, Arial, Sans-Serif;
  font-size: 14px;
  line-height: 1.4;
  padding: 6px 12px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #555;
  background-color: #fff;
  outline: none;
}

.search:disabled {
  cursor: not-allowed;
  background-color: #eee;
}

@media only screen and (max-width: 767px) {
  .search {
    display: none;
  }
}
