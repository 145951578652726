.text {
  font: var(--field-value-font, 14px Sans-Serif);
  outline: none;
  border-width: 0;
  padding: 0;
  margin: 0;
  align-self: stretch;
  flex-grow: 1;
  resize: none;
  background-color: transparent;
}

.text:invalid {
  outline: none;
  border-width: 0;
  margin: 0;
  box-shadow: none;
}

.text:disabled {
  opacity: var(--disabled-opacity, .4);
}

.input-row {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.copy-icon, .show-icon {
  margin-left: 16px;
  color: steelblue;
  font-size: 16px;
}

.copied-icon {
  composes: copy-icon;
  animation: magnify 0.2s linear 0s reverse;
}

@keyframes magnify {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(3.0);
  }
}
