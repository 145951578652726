.container {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
}

.list {

}

.item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font: var(--field-value-font, 14px Sans-Serif);
  padding: 4px 8px;
  min-width: 150px;
  cursor: pointer;
}

.selected-item {
  composes: item;
  background-color: #eee;
}
