.root {
  padding-top: 50px;
  padding-bottom: 16px;
}

.login {
  padding: 16px 16px 24px 16px;
}

.login-normal {
  composes: login;
  background: linear-gradient(to bottom right, #cca, #aa8);
}

.login-error {
  composes: login;
  background: linear-gradient(to bottom right, #daa, #b88);
}

.box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 6px 12px;
  border-radius: 16px;
  border: 1px solid #888;
  background-color: white;
  box-shadow: 2px 3px 3px #888;
  margin-top: 8px;
}

.input {
  flex-grow: 1;
  font-family: "Helvetica Neue", Helvetica, Arial, Sans-Serif;
  font-size: 14px;
  line-height: 1.4;
  color: #555;
  outline: none;
  border: 0;
}

.top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: white;
}

.message {
  margin-left: 10px;
  font: 20px "Helvetica Neue", Helvetica, Arial, Sans-Serif;
}

.icons {
  margin-right: 16px;
  font-size: 22px;
}
