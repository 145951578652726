.menu {
  display: grid;
  row-gap: 8px;
  column-gap: 8px;
  user-select: none;
  -webkit-user-select: none;
}

.item {
  outline: none;
  margin: 0;
  border-radius: var(--menu-item-border-radius, 16px);
  padding: var(--menu-item-padding, 5px 0);
  text-align: center;
  border: var(--menu-item-border, none);
  color: var(--menu-item-color, white);
  background: var(--menu-item-bg, transparent);
  font: var(--menu-item-font, 14px sans-serif);
  cursor: pointer;
}

.item:disabled {
  opacity: var(--disabled-opacity, 0.4);
  cursor: default;
}

.input {
  outline: none;
  border-radius: var(--menu-input-border-radius, 16px);
  border: var(--menu-input-border, unset);
  padding: var(--menu-input-padding, 5px 0);
  font: var(--menu-input-font, 14px sans-serif);
  color: var(--menu-input-color, white);
  background: var(--menu-input-bg, transparent);
  margin: 0;
  resize: none;
}

.input:focus {
  box-shadow: var(--menu-input-focus-box-shadow, unset);
}

.input:disabled {
  opacity: var(--disabled-opacity, 0.4);
}

@media (hover:hover)
{
  .item:hover:enabled {
    box-shadow: var(--menu-item-hover-box-shadow, unset);
  }
}
