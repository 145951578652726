.svg-container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
}

.svg-container svg {
  fill: currentColor;
  height: 1em;
}
