.root {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

@media only screen and (min-width: 1450px) and (max-width: 1800px) {
  .root {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1449px) {
  .root {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (min-width: 678px) and (max-width: 1023px) {
  .root {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 767px) {
  .root {
    grid-template-columns: 1fr;
  }
}
