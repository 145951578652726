.button {
  font-size: 100%;
  font-family: inherit;
  border: none;
  margin: 0;
  padding: 0;
  outline: none;
  cursor: pointer;
  background: transparent;
  color: inherit;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}

.button:focus {
  outline: none;
}

.disabled-button {
  composes: button;
  opacity: var(--disabled-opacity, .4);
  cursor: default;
}

.disabled-button:focus {
  outline: none;
}
